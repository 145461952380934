import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { isInDevelopmentEnv } from '../helpers/environment-utils';

const AuthorizationContext = createContext<{
    accountToken: string | null;
}>(null!);

type AuthorizationProps = {
    children: React.ReactNode;
};

export function AuthorizationProvider({ children }: AuthorizationProps) {
    const location = useLocation();
    const accountTokenInUrl = new URLSearchParams(location?.search).get('accountToken');
    const [accountToken, setAccountToken] = useState(accountTokenInUrl);
    const { replace: historyReplace } = useHistory();

    useEffect(() => {
        if (accountTokenInUrl) {
            if (accountTokenInUrl !== accountToken) {
                setAccountToken(accountTokenInUrl);
            }

            // We don't remove the accountToken from the URL in development to preserve it during website reloads while iterating on the source code.
            if (isInDevelopmentEnv) {
                return;
            }

            const updatedSearchParams = new URLSearchParams(location?.search);
            updatedSearchParams.delete('accountToken');

            historyReplace({ ...location, search: updatedSearchParams.toString() });
        }
    }, [accountToken, accountTokenInUrl, location, historyReplace]);

    return (
        <AuthorizationContext.Provider value={{ accountToken }}>
            {children}
        </AuthorizationContext.Provider>
    );
}

export function useAuthorization() {
    return useContext(AuthorizationContext);
}
