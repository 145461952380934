import { init } from '@sentry/browser';

import { isInProductionEnv } from 'src/shared/helpers/environment-utils';

if (isInProductionEnv) {
    init({
        dsn: 'https://bb435ff59b0d4617a5ddb76b1f261e33@sentry.uberall.ninja/7',
        environment: process.env.REACT_APP_CONTEXT || 'local',
        release: process.env.REACT_APP_RELEASE || undefined,
    });
}
