import {
    importResizeObserverPolyfill,
    importIntersectionObserverPolyfill,
} from 'src/shared/helpers/dynamic-imports';

export function loadPolyfills() {
    const polyfills = [];

    if (!supportsResizeObserver()) {
        polyfills.push(importResizeObserverPolyfill());
    }
    if (!supportsIntersectionObserver()) {
        polyfills.push(importIntersectionObserverPolyfill());
    }

    return Promise.all(polyfills);
}

function supportsResizeObserver() {
    return (
        'ResizeObserver' in global &&
        'ResizeObserverEntry' in global &&
        'contentRect' in (global as any).ResizeObserverEntry.prototype
    );
}

function supportsIntersectionObserver() {
    return 'IntersectionObserver' in global;
}
