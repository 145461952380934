const TRACK_USER_EVENTS_HTML_ATTRIBUTE_NAME = 'data-user-event';

/*
 * This function will return an object that contains the html attributes needed
 * for tracking users' events (currently done using heap), the return object of this
 * function should be spreaded as follows <div {...getUserEventAttr('some-value)} />
 */
const getUserEventAttr = (userEventId: string | undefined) => {
    if (!userEventId) return {};

    return { [TRACK_USER_EVENTS_HTML_ATTRIBUTE_NAME]: userEventId };
};

export { getUserEventAttr };
