import React, { Suspense, lazy } from 'react';
// This is the only place where the import is allowed
// eslint-disable-next-line no-restricted-imports
import { useTranslation as useTranslationOriginal } from 'react-i18next';
import { TOptions } from 'i18next';

import { useDevtools } from 'src/shared/contexts/devtools';
import { importDevtools } from 'src/shared/helpers/dynamic-imports';
import { translations } from 'src/shared/translations';

import { isInDevelopmentEnv } from './helpers/environment-utils';

export const useTranslation = isInDevelopmentEnv
    ? (useTranslationDev as typeof useTranslationOriginal)
    : useTranslationOriginal;

const TranslationReporter = lazy(() =>
    importDevtools().then((module) => ({ default: module.TranslationReporter }))
);

type TranslationProps = {
    code: string;
    interpolation?: {
        [k: string]: string | number;
    };
    className?: string;
};

export function Translation({ code, interpolation, ...props }: TranslationProps) {
    const { t } = useTranslation();
    const { enabled } = useDevtools();

    const translation = t(code, interpolation);

    return (
        <span {...props}>
            {enabled ? (
                <Suspense fallback={translation}>
                    <TranslationReporter code={code} interpolation={interpolation}>
                        {translation}
                    </TranslationReporter>
                </Suspense>
            ) : (
                translation
            )}
        </span>
    );
}

function useTranslationDev(...args: Parameters<typeof useTranslationOriginal>) {
    const { t: originalT, i18n, ready } = useTranslationOriginal(...args);

    function t(code: string, options?: TOptions) {
        const isInTranslationObject = code
            .split('.')
            .reduce(
                (translationObject, codePart) =>
                    (translationObject as { [k: string]: any } | undefined)?.[codePart],
                translations
            );

        if (!isInTranslationObject) {
            throw Error(
                `Translation code ${code} not found in translations object. More info in src/translations/README.md.`
            );
        }

        return originalT(code, options);
    }

    return { t, i18n, ready };
}

export { translations };
