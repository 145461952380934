import React, { createContext, useContext, useReducer, useCallback } from 'react';

import countries from 'src/shared/helpers/countries.json';

type Fields = {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    categories?: number[] | null;
    country?: keyof typeof countries | null;
    numberOfLocations?: number;
    businessName?: string;
    id?: number;
    token?: string;
    isSingleLocationCheck?: boolean;
};

type NearMeCheckContextValue = Fields & {
    updateField: (value: { [key in keyof Fields]: Fields[key] }) => void;
};

type NearMeCheckProviderProps = { children: React.ReactNode; defaultValues?: any };

type Action = {
    type: 'UPDATE_VALUES';
    payload: Fields;
};

const NearMeCheckContext = createContext<NearMeCheckContextValue>(null!);

function valuesReducer(state: NearMeCheckContextValue, action: Action) {
    switch (action.type) {
        case 'UPDATE_VALUES': {
            return {
                ...state,
                ...action.payload,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export function NearMeCheckProvider({ children, defaultValues }: NearMeCheckProviderProps) {
    const [state, dispatch] = useReducer(valuesReducer, {
        updateField: null!,
    });

    const updateField = useCallback((value) => {
        dispatch({
            type: 'UPDATE_VALUES',
            payload: value,
        });
    }, []);

    const value = { ...state, dispatch, updateField, ...defaultValues };

    return <NearMeCheckContext.Provider value={value}>{children}</NearMeCheckContext.Provider>;
}

export function useNearMeCheckData() {
    return useContext(NearMeCheckContext);
}
