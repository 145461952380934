/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { Suspense } from 'react';
import { showReportDialog } from '@sentry/browser';

import { useTheme } from 'src/theme';
import { useTranslation } from 'src/shared/translation';
import { ReactComponent as SadStore } from 'src/assets/banners/error-desktop.svg';
import { getUserEventAttr } from 'src/shared/helpers/tracking-user-events';

function useTranslationWithFallback() {
    const { t } = useTranslation();

    return function tWithFallback(code: string, fallback: string) {
        // We can't use t(code, fallback) mechanism because our API might return code itself as translation. t() then wrongly assumes that translation exists, returning the code instead of the fallback.
        const translation = t(code);

        if (translation && translation !== code) {
            return translation;
        }
        return fallback;
    };
}

function ReportFeedbackButton({ sentryEventId }: { sentryEventId: string }) {
    const t = useTranslationWithFallback();

    return (
        <button
            type="button"
            sx={{
                backgroundColor: 'whitelabel.buttonBackground',
                border: '1px solid',
                borderColor: 'whitelabel.buttonBackground',
                borderRadius: 3,
                fontSize: 16,
                color: 'nearMeCheck.white',
                lineHeight: 1.5,
                padding: '5px 12px',
                cursor: 'pointer',
                transition: 'transform 0.2s',
                width: 'fit-content',
                marginTop: ['32px', '0'],
            }}
            onClick={() => showReportDialog({ eventId: sentryEventId })}
            {...getUserEventAttr('nmc-error-feedback')}
        >
            {t('nearmecheck.fatal_error.report_feedback_button', 'Report feedback')}
        </button>
    );
}

function OopsMessage({ sentryEventId }: { sentryEventId?: string | null }) {
    const t = useTranslationWithFallback();

    return (
        <div
            sx={{
                display: 'flex',
                alignItems: ['center', 'flex-start'],
                flexDirection: 'column',
                width: 'fit-content',
            }}
        >
            <h1 sx={{ margin: ['0 0 16px 0', '0 0 32px 0'], fontSize: 8 }}>
                {t('nearmecheck.fatal_error.title', 'Oops!')}
            </h1>
            <p>{t('nearmecheck.fatal_error.message', 'An error happened. We are sorry.')}</p>
            {sentryEventId ? <ReportFeedbackButton sentryEventId={sentryEventId} /> : null}
        </div>
    );
}

export function Oops({ sentryEventId }: { sentryEventId?: string | null }) {
    const { theme } = useTheme();
    const displayBottomRectangle = theme.colors.whitelabel.buttonBackground === '#3096DE';

    return (
        <div
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: ['column', 'row'],
                height: '100vh',
                backgroundColor: ['nearMeCheck.white', 'grey.3'],
            }}
        >
            <div
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: ['fit-content', '606px'],
                    width: ['fit-content', '27em'],
                    maxWidth: ['95%', '40%'],
                    backgroundColor: ['transparent', 'whitelabel.buttonBackground'],
                    position: 'relative',
                    margin: ['0', '48px 0 48px 48px'],
                }}
            >
                <SadStore
                    sx={{
                        maxWidth: ['95%', '100%'],
                        '& > path': {
                            stroke: ['whitelabel.buttonBackground', 'nearMeCheck.white'],
                        },
                    }}
                />

                {displayBottomRectangle && (
                    <div
                        sx={{
                            display: ['none', 'block'],
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            height: 80,
                            maxHeight: '15%',
                            width: '56%',
                            backgroundColor: 'nearMeCheck.blue.brand',
                        }}
                    />
                )}
            </div>
            <div
                sx={{
                    zIndex: 1,
                    width: ['100%', '38.5em'],
                    maxWidth: ['100%', '60%'],
                    padding: [32, 68],
                    background: ['transparent', 'nearMeCheck.white'],
                    height: ['fit-content', '606px'],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: ['0 0 0 0', '48px 48px 48px 0'],
                    color: 'blue.darkest',
                }}
            >
                <Suspense fallback={null}>
                    <OopsMessage sentryEventId={sentryEventId} />
                </Suspense>
            </div>
        </div>
    );
}
