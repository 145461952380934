import React from 'react';

import { NEARMECHECK_ROUTES } from 'src/routes';

const StartPage = React.lazy(
    () => import(/* webpackChunkName: "start-page" */ './pages/start-page')
);

const GmbSelectLocationPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: "gmb-select-location-page" */ './pages/gmb-connect-flow/select-locations-page'
        )
);

const GmbConfirmSelectionPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: "gmb-confirm-selection-page" */ './pages/gmb-connect-flow/confirm-selection-page'
        )
);

const MultiLocationResultsPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: "multi-location-results-page" */ './pages/results-page/multi-location/multi-location-results-page'
        )
);

const ResultsWithIdPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: "results-with-id-page" */ './pages/results-page/single-location/results-with-id-page'
        )
);

const ResultsWithSearchParamsPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: "results-with-search-params-page" */ './pages/results-page/single-location/results-with-search-params-page'
        )
);

const FailurePage = React.lazy(
    () => import(/* webpackChunkName: "failure-page" */ './pages/failure-page')
);

export const nearMeCheckRoutes = [
    {
        path: NEARMECHECK_ROUTES.START,
        component: <StartPage />,
    },
    {
        path: NEARMECHECK_ROUTES.SELECT_LOCATION,
        component: <GmbSelectLocationPage />,
    },
    {
        path: NEARMECHECK_ROUTES.CONFIRM_SELECTION,
        component: <GmbConfirmSelectionPage />,
    },
    {
        path: NEARMECHECK_ROUTES.RESULTS,
        component: <ResultsWithSearchParamsPage />,
    },
    {
        path: `${NEARMECHECK_ROUTES.RESULTS}/:id`,
        component: <ResultsWithIdPage />,
    },
    {
        path: `${NEARMECHECK_ROUTES.MULTI_LOCATION_RESULTS}/:id`,
        component: <MultiLocationResultsPage />,
    },
    {
        path: NEARMECHECK_ROUTES.FAILURE,
        component: <FailurePage />,
    },
];
