import React from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import extraScopePlugin from 'stylis-plugin-extra-scope';

/* 
This component was created as a temporary fix to the override of our styles when used as a widget.

As part of this ticket: https://jira.uberall.com/browse/UB-35310 the current approach shall be revisited 
and replaced with a better, less invasive, approach.

*/

type ExtraScopeProps = {
    children: React.ReactNode;
    scope?: string;
};

export function ExtraScope({ children, scope }: ExtraScopeProps) {
    const scopeSelector = scope
        ? `div#uberall-near-me-check div#${scope}`
        : 'div#uberall-near-me-check';
    const cache = createCache({
        key: 'uberall-nmc',
        stylisPlugins: [extraScopePlugin(scopeSelector)],
    });

    return (
        <div id={scope}>
            <CacheProvider value={cache}>{children}</CacheProvider>
        </div>
    );
}
