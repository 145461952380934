import { default as _11880 } from './png/DIRECTORY_LOGO_PLACEHOLDER.png';
import { default as ABCLOCAL } from './png/ABCLOCAL.png';
import { default as ACOMPIO } from './png/ACOMPIO.png';
import { default as AL_DIA_TX } from './png/AL_DIA_TX.png';
import { default as ALEXA } from './png/ALEXA.png';
import { default as ANNUAIRE } from './png/ANNUAIRE.png';
import { default as APPLE_MAPS } from './png/APPLE_MAPS.png';
import { default as AUDI } from './png/AUDI.png';
import { default as AUSKUNFT } from './png/AUSKUNFT.png';
import { default as AUSSIE_WEB } from './png/AUSSIE_WEB.png';
import { default as BAIDU } from './png/BAIDU.png';
import { default as BANK_OEFFNUNGSZEITEN_DE_MANUAL } from './png/BANK_OEFFNUNGSZEITEN_DE_MANUAL.png';
import { default as BANKOEFFNUNGSZEITEN_DE_MANUAL } from './png/BANKOEFFNUNGSZEITEN_DE_MANUAL.png';
import { default as BELL_CA } from './png/BELL_CA.png';
import { default as BING } from './png/BING.png';
import { default as BING_HEALTH } from './png/BING_HEALTH.png';
import { default as BMW } from './png/BMW.png';
import { default as BONIAL_FR } from './png/BONIAL_FR.png';
import { default as BRANCHEN_INFO_MANUAL } from './png/BRANCHEN_INFO_MANUAL.png';
import { default as BRANCHENBUCH_DEUTSCHLAND } from './png/BRANCHENBUCH_DEUTSCHLAND.png';
import { default as BROWNBOOK } from './png/BROWNBOOK.png';
import { default as BUNDES_TELEFONBUCH } from './png/BUNDES_TELEFONBUCH.png';
import { default as BUSINESSBRANCHENBUCH } from './png/BUSINESSBRANCHENBUCH.png';
import { default as BUSQUEDA_LOCAL } from './png/BUSQUEDA_LOCAL.png';
import { default as CARE_DASH } from './png/CARE_DASH.png';
import { default as CBANQUE } from './png/CBANQUE.png';
import { default as CENTRAL_INDEX } from './png/CENTRAL_INDEX.png';
import { default as CHAMBER_OF_COMMERCE } from './png/CHAMBER_OF_COMMERCE.png';
import { default as CHRON } from './png/CHRON.png';
import { default as CITIPAGES } from './png/CITIPAGES.png';
import { default as CITY_SQUARES } from './png/CITY_SQUARES.png';
import { default as CITYSEARCH } from './png/CITYSEARCH.png';
import { default as CYLEX } from './png/CYLEX.png';
import { default as CYLEX_MANUAL } from './png/CYLEX_MANUAL.png';
import { default as D_11880_COM_MANUAL } from './png/D_11880_COM_MANUAL.png';
import { default as DAS_SCHNELLE } from './png/DAS_SCHNELLE.png';
import { default as DENTAL_PLANS } from './png/DENTAL_PLANS.png';
import { default as DEX_KNOWS } from './png/DEX_KNOWS.png';
import { default as DIALO } from './png/DIALO.png';
import { default as DOC_SPOT } from './png/DOC_SPOT.png';
import { default as DOCTOR_COM } from './png/DOCTOR_COM.png';
import { default as EL_TIEMPO_LATINO } from './png/EL_TIEMPO_LATINO.png';
import { default as ELOCAL } from './png/ELOCAL.png';
import { default as ETRUSTED } from './png/ETRUSTED.png';
import { default as EZLOCAL } from './png/EZLOCAL.png';
import { default as FACEBOOK } from './png/FACEBOOK.png';
import { default as FACTUAL } from './png/FACTUAL.png';
import { default as FIAT } from './png/FIAT.png';
import { default as FIND_OPEN } from './png/FIND_OPEN.png';
import { default as FINDE_OFFEN_MANUAL } from './png/FINDE_OFFEN_MANUAL.png';
import { default as FOCUS } from './png/FOCUS.png';
import { default as FORD } from './png/FORD.png';
import { default as FOURSQUARE } from './png/FOURSQUARE.png';
import { default as FREIEAUSKUNFT } from './png/FREIEAUSKUNFT.png';
import { default as GARMIN } from './png/GARMIN.png';
import { default as GELBE_SEITEN } from './png/GELBE_SEITEN.png';
import { default as GM } from './png/GM.png';
import { default as GMX } from './png/GMX.png';
import { default as GO_LOCAL } from './png/GO_LOCAL.png';
import { default as GO_YELLOW } from './png/GO_YELLOW.png';
import { default as GOOGLE } from './png/GOOGLE.png';
import { default as GOOGLE_ASSISTANT } from './png/GOOGLE_ASSISTANT.png';
import { default as GOOGLE_MAPS } from './png/GOOGLE_MAPS.png';
import { default as GUIDELOCAL } from './png/GUIDELOCAL.png';
import { default as GUTE_BANKEN_MANUAL } from './png/GUTE_BANKEN_MANUAL.png';
import { default as HEALTHGRADES } from './png/HEALTHGRADES.png';
import { default as HOLIDAY_CHECK } from './png/HOLIDAY_CHECK.png';
import { default as HOTFROG } from './png/HOTFROG.png';
import { default as HUAWEI } from './png/HUAWEI.png';
import { default as I_GLOBAL } from './png/I_GLOBAL.png';
import { default as INFO_IS_INFO } from './png/INFO_IS_INFO.png';
import { default as INFOBEL } from './png/INFOBEL.png';
import { default as INFOGROUP } from './png/INFOGROUP.png';
import { default as INSIDER_PAGES } from './png/INSIDER_PAGES.png';
import { default as INSTAGRAM } from './png/INSTAGRAM.png';
import { default as JELLOO } from './png/JELLOO.png';
import { default as JUDYS_BOOK } from './png/JUDYS_BOOK.png';
import { default as JUSTACOTE } from './png/JUSTACOTE.png';
import { default as KAUFDA_MANUAL } from './png/KAUFDA_MANUAL.png';
import { default as KOOMIO } from './png/KOOMIO.png';
import { default as LA_VOZ_TX } from './png/LA_VOZ_TX.png';
import { default as LATINOS_US } from './png/LATINOS_US.png';
import { default as LIVERPOOL_ECHO } from './png/LIVERPOOL_ECHO.png';
import { default as LOCALSTACK } from './png/LOCALSTACK.png';
import { default as LOKALEAUSKUNFT } from './png/LOKALEAUSKUNFT.png';
import { default as LOOCAL } from './png/LOOCAL.png';
import { default as MANTA } from './png/MANTA.png';
import { default as MARKTPLATZ_MITTELSTAND } from './png/MARKTPLATZ_MITTELSTAND.png';
import { default as MEIN_PROSPEKT_MANUAL } from './png/MEIN_PROSPEKT_MANUAL.png';
import { default as MEINESTADT } from './png/MEINESTADT.png';
import { default as MEINUNGSMEISTER } from './png/MEINUNGSMEISTER.png';
import { default as MERCEDES } from './png/MERCEDES.png';
import { default as MICROSOFT_CORTANA } from './png/MICROSOFT_CORTANA.png';
import { default as MUNDO_HISPANICO } from './png/MUNDO_HISPANICO.png';
import { default as MY_LOCAL_SERVICES } from './png/MY_LOCAL_SERVICES.png';
import { default as N49 } from './png/N49.png';
import { default as NAVMII } from './png/NAVMII.png';
import { default as NAVTEQ } from './png/NAVTEQ.png';
import { default as NEUSTAR } from './png/NEUSTAR.png';
import { default as NEXT_DOOR } from './png/NEXT_DOOR.png';
import { default as NOKIA_HERE } from './png/NOKIA_HERE.png';
import { default as NPPES } from './png/NPPES.png';
import { default as OEFFNUNGSZEITENBUCH } from './png/OEFFNUNGSZEITENBUCH.png';
import { default as ONE_AND_ONE } from './png/ONE_AND_ONE.png';
import { default as ONE_NINE_TWO } from './png/ONE_NINE_TWO.png';
import { default as ORANGE_118_712 } from './png/ORANGE_118_712.png';
import { default as PAGES24 } from './png/PAGES24.png';
import { default as PAGES_JAUNES } from './png/PAGES_JAUNES.png';
import { default as PALM_BEACH_POST } from './png/PALM_BEACH_POST.png';
import { default as PRATIQUE } from './png/PRATIQUE.png';
import { default as RATE_MDS } from './png/RATE_MDS.png';
import { default as REGIONAL_DE } from './png/REGIONAL_DE.png';
import { default as RICERCARE_IMPRESE } from './png/RICERCARE_IMPRESE.png';
import { default as SCOOT } from './png/SCOOT.png';
import { default as SENSIS_TRUE_LOCAL } from './png/SENSIS_TRUE_LOCAL.png';
import { default as SENSIS_WHITE_PAGES } from './png/SENSIS_WHITE_PAGES.png';
import { default as SENSIS_YELLOW_PAGES } from './png/SENSIS_YELLOW_PAGES.png';
import { default as SHARE_CARE } from './png/SHARE_CARE.png';
import { default as SHOPPING_TIME_NETWORK } from './png/SHOPPING_TIME_NETWORK.png';
import { default as SHOWMELOCAL } from './png/SHOWMELOCAL.png';
import { default as SIRI } from './png/SIRI.png';
import { default as STADTBRANCHENBUCH } from './png/STADTBRANCHENBUCH.png';
import { default as START_LOCAL } from './png/START_LOCAL.png';
import { default as STATESMAN } from './png/STATESMAN.png';
import { default as SUPER_PAGES } from './png/SUPER_PAGES.png';
import { default as THE_DAILY_RECORD } from './png/THE_DAILY_RECORD.png';
import { default as THE_EVENING_STANDARD } from './png/THE_EVENING_STANDARD.png';
import { default as THE_INDEPENDENT } from './png/THE_INDEPENDENT.png';
import { default as THE_MIRROR } from './png/THE_MIRROR.png';
import { default as THE_SCOTSMAN } from './png/THE_SCOTSMAN.png';
import { default as THE_SUN } from './png/THE_SUN.png';
import { default as TOMTOM } from './png/TOMTOM.png';
import { default as TOUCH_LOCAL } from './png/TOUCH_LOCAL.png';
import { default as TOYOTA } from './png/TOYOTA.png';
import { default as TRIP_ADVISOR } from './png/TRIP_ADVISOR.png';
import { default as TRUE_LOCAL } from './png/TRUE_LOCAL.png';
import { default as TRUSTPILOT } from './png/TRUSTPILOT.png';
import { default as TUPALO } from './png/TUPALO.png';
import { default as UBER } from './png/UBER.png';
import { default as UBER_NEW } from './png/UBER_NEW.png';
import { default as UNIVISION } from './png/UNIVISION.png';
import { default as UNTERNEHMENSAUSKUNFT } from './png/UNTERNEHMENSAUSKUNFT.png';
import { default as US_CITY } from './png/US_CITY.png';
import { default as US_INFO_COM } from './png/US_INFO_COM.png';
import { default as VITALS } from './png/VITALS.png';
import { default as VW } from './png/VW.png';
import { default as WAND } from './png/WAND.png';
import { default as WAZE } from './png/WAZE.png';
import { default as WAZE_NEW } from './png/WAZE_NEW.png';
import { default as WEB_DE } from './png/WEB_DE.png';
import { default as WEB_MD } from './png/WEB_MD.png';
import { default as WELLNESS } from './png/WELLNESS.png';
import { default as WHERE_TO } from './png/WHERE_TO.png';
import { default as WHITE_PAGES } from './png/WHITE_PAGES.png';
import { default as WO_GIBTS_WAS } from './png/WO_GIBTS_WAS.png';
import { default as WOMO } from './png/WOMO.png';
import { default as YA_SABE } from './png/YA_SABE.png';
import { default as YAHOO_MANUAL } from './png/YAHOO_MANUAL.png';
import { default as YALWA } from './png/YALWA.png';
import { default as YANDEX } from './png/YANDEX.png';
import { default as YELLBO } from './png/YELLBO.png';
import { default as YELLOW_MAP } from './png/YELLOW_MAP.png';
import { default as YELLOW_PAGES } from './png/YELLOW_PAGES.png';
import { default as YELLOW_PAGES_SINGAPORE } from './png/YELLOW_PAGES_SINGAPORE.png';
import { default as YELP } from './png/YELP.png';
import { default as YELP_API } from './png/YELP_API.png';
import { default as YELP_MANUAL } from './png/YELP_MANUAL.png';
import { default as YP_COM_HEALTH } from './png/YP_COM_HEALTH.png';
import { default as ZIP_CH } from './png/ZIP_CH.png';
import { default as ZWIVEL } from './png/ZWIVEL.png';

const directoryLogos = {
    '11880': _11880, // not possible to import 11880 because variables cannot start with a number in JS
    ABCLOCAL,
    ACOMPIO,
    AL_DIA_TX,
    ALEXA,
    ANNUAIRE,
    APPLE_MAPS,
    AUDI,
    AUSKUNFT,
    AUSSIE_WEB,
    BAIDU,
    BANK_OEFFNUNGSZEITEN_DE_MANUAL,
    BANKOEFFNUNGSZEITEN_DE_MANUAL,
    BELL_CA,
    BING,
    BING_HEALTH,
    BMW,
    BONIAL_FR,
    BRANCHEN_INFO_MANUAL,
    BRANCHENBUCH_DEUTSCHLAND,
    BROWNBOOK,
    BUNDES_TELEFONBUCH,
    BUSINESSBRANCHENBUCH,
    BUSQUEDA_LOCAL,
    CARE_DASH,
    CBANQUE,
    CENTRAL_INDEX,
    CHAMBER_OF_COMMERCE,
    CHRON,
    CITIPAGES,
    CITY_SQUARES,
    CITYSEARCH,
    CYLEX,
    CYLEX_MANUAL,
    D_11880_COM_MANUAL,
    DAS_SCHNELLE,
    DENTAL_PLANS,
    DEX_KNOWS,
    DIALO,
    DOC_SPOT,
    DOCTOR_COM,
    EL_TIEMPO_LATINO,
    ELOCAL,
    ETRUSTED,
    EZLOCAL,
    FACEBOOK,
    FACTUAL,
    FIAT,
    FIND_OPEN,
    FINDE_OFFEN_MANUAL,
    FOCUS,
    FORD,
    FOURSQUARE,
    FREIEAUSKUNFT,
    GARMIN,
    GELBE_SEITEN,
    GM,
    GMX,
    GO_LOCAL,
    GO_YELLOW,
    GOOGLE,
    GOOGLE_ASSISTANT,
    GOOGLE_MAPS,
    GUIDELOCAL,
    GUTE_BANKEN_MANUAL,
    HEALTHGRADES,
    HOLIDAY_CHECK,
    HOTFROG,
    HUAWEI,
    I_GLOBAL,
    INFO_IS_INFO,
    INFOBEL,
    INFOGROUP,
    INSIDER_PAGES,
    INSTAGRAM,
    JELLOO,
    JUDYS_BOOK,
    JUSTACOTE,
    KAUFDA_MANUAL,
    KOOMIO,
    LA_VOZ_TX,
    LATINOS_US,
    LIVERPOOL_ECHO,
    LOCALSTACK,
    LOKALEAUSKUNFT,
    LOOCAL,
    MANTA,
    MARKTPLATZ_MITTELSTAND,
    MEIN_PROSPEKT_MANUAL,
    MEINESTADT,
    MEINUNGSMEISTER,
    MERCEDES,
    MICROSOFT_CORTANA,
    MUNDO_HISPANICO,
    MY_LOCAL_SERVICES,
    N49,
    NAVMII,
    NAVTEQ,
    NEUSTAR,
    NEXT_DOOR,
    NOKIA_HERE,
    NPPES,
    OEFFNUNGSZEITENBUCH,
    ONE_AND_ONE,
    ONE_NINE_TWO,
    ORANGE_118_712,
    PAGES24,
    PAGES_JAUNES,
    PALM_BEACH_POST,
    PRATIQUE,
    RATE_MDS,
    REGIONAL_DE,
    RICERCARE_IMPRESE,
    SCOOT,
    SENSIS_TRUE_LOCAL,
    SENSIS_WHITE_PAGES,
    SENSIS_YELLOW_PAGES,
    SHARE_CARE,
    SHOPPING_TIME_NETWORK,
    SHOWMELOCAL,
    SIRI,
    STADTBRANCHENBUCH,
    START_LOCAL,
    STATESMAN,
    SUPER_PAGES,
    THE_DAILY_RECORD,
    THE_EVENING_STANDARD,
    THE_INDEPENDENT,
    THE_MIRROR,
    THE_SCOTSMAN,
    THE_SUN,
    TOMTOM,
    TOUCH_LOCAL,
    TOYOTA,
    TRIP_ADVISOR,
    TRUE_LOCAL,
    TRUSTPILOT,
    TUPALO,
    UBER,
    UBER_NEW,
    UNIVISION,
    UNTERNEHMENSAUSKUNFT,
    US_CITY,
    US_INFO_COM,
    VITALS,
    VW,
    WAND,
    WAZE,
    WAZE_NEW,
    WEB_DE,
    WEB_MD,
    WELLNESS,
    WHERE_TO,
    WHITE_PAGES,
    WO_GIBTS_WAS,
    WOMO,
    YA_SABE,
    YAHOO_MANUAL,
    YALWA,
    YANDEX,
    YELLBO,
    YELLOW_MAP,
    YELLOW_PAGES,
    YELLOW_PAGES_SINGAPORE,
    YELP,
    YELP_API,
    YELP_MANUAL,
    YP_COM_HEALTH,
    ZIP_CH,
    ZWIVEL,
};

export { directoryLogos };
