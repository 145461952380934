import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import { isInDevelopmentEnv } from 'src/shared/helpers/environment-utils';
import languages from 'src/shared/helpers/languages.json';

import { NEARMECHECK_API_URL } from '../near-me-check/api/fetch-from-backend';

// Prevent accidental overfetching
const MESSAGES_LIMIT = 2000;
let MESSAGES_API_URL = NEARMECHECK_API_URL;

if (isInDevelopmentEnv && process.env.REACT_APP_DEV_MESSAGES_PROXY_CACHE === 'true') {
    MESSAGES_API_URL = '/api';

    // eslint-disable-next-line no-console
    console.log('Messages proxy cache is enabled.');
}

export function initI18next(language: keyof typeof languages) {
    i18next
        .use(XHR)
        .use(initReactI18next)
        .init({
            lng: language,
            fallbackLng: false,
            nsSeparator: false,
            keySeparator: false,
            interpolation: {
                prefix: '[',
                suffix: ']',
                escapeValue: false,
            },
            backend: {
                loadPath: `${MESSAGES_API_URL}/messages?language=[lng]&max=${MESSAGES_LIMIT}`,
                crossDomain: true,
            },
        });
}
