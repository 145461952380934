import React, { Component } from 'react';
import { withScope, captureException, Severity } from '@sentry/browser';

import { Oops } from 'src/near-me-check/pages/fatal-error-page/oops';

import { isInProductionEnv } from './helpers/environment-utils';

class SentryErrorBoundary extends Component<{}, { error: unknown; eventId: string | null }> {
    constructor(props: {}) {
        super(props);
        this.state = { error: null, eventId: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({ error });

        withScope((scope) => {
            scope.setExtras(errorInfo);
            scope.setLevel(Severity.Fatal);
            const eventId = captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        const { error, eventId } = this.state;

        if (!isInProductionEnv) {
            return error ? null : this.props.children;
        }

        return error ? <Oops sentryEventId={eventId} /> : this.props.children;
    }
}

export default SentryErrorBoundary;
