import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// We want to start Sentry before anything else, even before importing anything except polyfills.
import './init/init-sentry';

import React from 'react';
import ReactDOM from 'react-dom';

import { init } from './init';
import { App } from './app';

init(document.getElementById('uberall-near-me-check')).then(({ props, rootNode }) => {
    ReactDOM.render(<App {...props} />, rootNode);
});
