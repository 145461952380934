import React, { createContext, useContext } from 'react';

import countries from 'src/shared/helpers/countries.json';
import languages from 'src/shared/helpers/languages.json';

export type AppConfigContextValue = {
    publicKey: string;
    language: keyof typeof languages;
    countries: Partial<typeof countries>;
    loginUrl?: string;
    privacyPolicyUrl?: string;
    title?: string;
    subtitle?: string;
    reportName: string;
    ctaUrlSearchOptimization: string;
    ctaUrlLocationAccuracy: string;
    ctaUrlEngagement: string;
    ctaUrlConversions: string;
    ctaUrlResultsFooter: string;
    signupCountries: Partial<typeof countries>;
    signupEnabled?: boolean;
    printReportEnabled?: boolean;
    whiteLabelPrintReportLogo?: string;
    showUncheckedDirectories?: boolean;
    hideUberallAttribution?: boolean;
    hideGoogleConnectFlow?: boolean;
    hideResultsFooter?: boolean;
    hideReplyRate?: boolean;
    hideLocationAccuracy?: boolean;
    hideSearchOptimization?: boolean;
    hideConversions?: boolean;
    hideEngagement?: boolean;
    hideSummary?: boolean;
    hideManagedLocationsDetails?: boolean;
    hideConclusion?: boolean;
    positionListingsDetails?: number;
    hideListingsDetails?: boolean;
    hideGlobalScore?: boolean;
    emailFieldVisibility: 'optional' | 'mandatory' | 'hidden';
    phoneNumberFieldVisibility: 'optional' | 'mandatory' | 'hidden';
};

type AppConfigProviderProps = { children: React.ReactNode } & AppConfigContextValue;

const AppConfigContext = createContext<AppConfigContextValue>(null!);

export function AppConfigProvider({ children, ...props }: AppConfigProviderProps) {
    return <AppConfigContext.Provider value={props}>{children}</AppConfigContext.Provider>;
}

export function useAppConfig() {
    return useContext(AppConfigContext);
}
