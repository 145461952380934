export const SIGNUP_ROUTES = {
    ACCOUNT_CREATION: '/signup',
    BUSINESS_DETAILS: '/business-details',
    SELECT_PLAN: '/select-plan',
    PAYMENT: '/payment',
    REVIEW: '/review',
    SUCCESS: '/success',
};

export const NEARMECHECK_ROUTES = {
    START: '/',
    SELECT_LOCATION: '/gmb/select-location',
    CONFIRM_SELECTION: '/gmb/confirm-selection',
    RESULTS: '/results',
    MULTI_LOCATION_RESULTS: '/multi-location-results',
    FAILURE: '/gmb/failure',
};
