import React from 'react';
import { ThemeProvider } from '@theme-ui/core';

import { nearMeCheckTheme } from 'src/theme';

type AppThemeProviderProps = WhitelabelOverrides & {
    children: React.ReactNode;
};

type WhitelabelOverrides = {
    colorButtonBackground?: string;
    colorSuccess?: string;
    colorFailure?: string;
};

function getWhitelabelledTheme({
    colorButtonBackground,
    colorSuccess,
    colorFailure,
}: WhitelabelOverrides) {
    const { whitelabel } = nearMeCheckTheme.colors;

    return {
        ...nearMeCheckTheme,
        colors: {
            ...nearMeCheckTheme.colors,
            whitelabel: {
                ...whitelabel,
                buttonBackground: colorButtonBackground || whitelabel.buttonBackground,
                buttonHoverBackground: colorButtonBackground || whitelabel.buttonHoverBackground,
                buttonActiveBackground: colorButtonBackground || whitelabel.buttonActiveBackground,
                success: colorSuccess || whitelabel.success,
                failure: colorFailure || whitelabel.failure,
            },
        },
    };
}

export function AppThemeProvider({ children, ...whitelabelOverrides }: AppThemeProviderProps) {
    const whitelabelledTheme = getWhitelabelledTheme(whitelabelOverrides);

    return <ThemeProvider theme={whitelabelledTheme}>{children}</ThemeProvider>;
}
