import React, { Suspense } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';

import { useAppConfig } from 'src/shared/contexts/app-config';
import { NEARMECHECK_ROUTES } from 'src/routes';
import { isInDevelopmentEnv } from 'src/shared/helpers/environment-utils';

import { AuthorizationProvider } from './shared/contexts/authorization';
import { SpinnerFullPage } from './near-me-check/components/spinner';
import { NearMeCheckProvider } from './shared/contexts/near-me-check-context';
import { nearMeCheckRoutes } from './near-me-check/router';
import { signupRoutes } from './self-service-signup/router';

export function AppRouter() {
    const { signupEnabled } = useAppConfig();

    // For easier testing, Sign Up flow routes are always available in dev environment
    const appRoutes =
        signupEnabled || isInDevelopmentEnv
            ? [...nearMeCheckRoutes, ...signupRoutes]
            : nearMeCheckRoutes;

    return (
        <HashRouter>
            <Suspense fallback={<SpinnerFullPage />}>
                <AuthorizationProvider>
                    <NearMeCheckProvider>
                        <Switch>
                            {appRoutes.map((route) => {
                                return (
                                    <Route exact path={route.path} key={route.path}>
                                        {route.component}
                                    </Route>
                                );
                            })}

                            {/*  DEFAULT ROUTE */}
                            <Route>
                                <Redirect to={NEARMECHECK_ROUTES.START} />
                            </Route>
                        </Switch>
                    </NearMeCheckProvider>
                </AuthorizationProvider>
            </Suspense>
        </HashRouter>
    );
}
