import React, { lazy, useState, useEffect, Suspense } from 'react';

import { importDevtools } from 'src/shared/helpers/dynamic-imports';

const DEVTOOLS = {
    KEY: 'devtools',
    VALUE: 'true',
};
const DevtoolsProvider = lazy(() =>
    importDevtools().then((module) => ({ default: module.DevtoolsProvider }))
);

export function Devtools({ children }: { children: JSX.Element }) {
    const { showDevtools, hideDevtools } = useShowDevtools();

    return showDevtools ? (
        // We don't want to render children as a fallback to prevent lost state in AuthorizationProvider due to remount of subtree.
        <Suspense fallback={null}>
            <DevtoolsProvider hideDevtools={hideDevtools}>{children}</DevtoolsProvider>
        </Suspense>
    ) : (
        children
    );
}

function useShowDevtools() {
    const [showDevtools, setShowDevtools] = useState(getShowDevtools);

    useEffect(() => {
        if (showDevtools) {
            window.localStorage?.setItem(DEVTOOLS.KEY, DEVTOOLS.VALUE);
        }
    }, [showDevtools]);

    function hideDevtools({ permanently }: { permanently?: boolean } = {}) {
        setShowDevtools(false);

        if (permanently) {
            window.localStorage?.removeItem(DEVTOOLS.KEY);
        }
    }

    return { showDevtools, hideDevtools };
}

function getShowDevtools() {
    const { search, hash } = window.location;
    const devToolsInSearchParams = new URLSearchParams(search).get(DEVTOOLS.KEY) === DEVTOOLS.VALUE;
    const hasSearchInHash = hash.includes('?');
    const searchInHash = hasSearchInHash ? hash.substring(hash.indexOf('?') + 1) : '';
    const devToolsInHash = new URLSearchParams(searchInHash).get(DEVTOOLS.KEY) === DEVTOOLS.VALUE;

    return (
        devToolsInSearchParams ||
        devToolsInHash ||
        window.localStorage?.getItem(DEVTOOLS.KEY) === DEVTOOLS.VALUE
    );
}
