/** @jsx jsx */
import { jsx, ThemeUIStyleObject } from '@theme-ui/core';

type SpinnerProps = {
    inline?: boolean;
    width?: number;
    strokeWidth?: number;
    sx?: ThemeUIStyleObject;
    color?: string;
};

export function Spinner({ inline, strokeWidth = 6, ...props }: SpinnerProps) {
    return (
        <svg
            viewBox="0 0 50 50"
            aria-busy="true"
            aria-live="polite"
            width="1em"
            sx={{
                display: inline ? 'inline' : 'block',
                verticalAlign: 'middle',
                animation: 'spinner-rotate 2s linear infinite',
                '@keyframes spinner-rotate': {
                    '100%': {
                        transform: 'rotate(360deg)',
                    },
                },
            }}
            {...props}
        >
            <circle
                cx={25}
                cy={25}
                r={20}
                strokeWidth={strokeWidth}
                strokeMiterlimit={10}
                sx={{
                    stroke: 'nearMeCheck.grey.2',
                    opacity: 0.5,
                    fill: 'none',
                }}
            />
            <circle
                cx={25}
                cy={25}
                r={20}
                strokeWidth={strokeWidth}
                strokeMiterlimit={10}
                sx={{
                    stroke: 'currentColor',
                    fill: 'none',
                    animation: 'spinner-dash 1.5s ease-in-out infinite',
                    '@keyframes spinner-dash': {
                        '0%': {
                            strokeDasharray: '1, 200',
                            strokeDashoffset: 0,
                        },
                        '50%': {
                            strokeDasharray: '89, 200',
                            strokeDashoffset: '-35px',
                        },
                        '100%': {
                            strokeDasharray: '89, 200',
                            strokeDashoffset: '-124px',
                        },
                    },
                }}
            />
        </svg>
    );
}

export function SpinnerArea(props: any) {
    return (
        <div
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            {...props}
        >
            <Spinner sx={{ color: 'nearMeCheck.blue.light' }} />
        </div>
    );
}

export function SpinnerFullPage() {
    return (
        <div
            sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Spinner width={50} strokeWidth={3} sx={{ color: 'nearMeCheck.blue.light' }} />
        </div>
    );
}
