import React from 'react';

import { SIGNUP_ROUTES } from 'src/routes';

const AccountCreationPage = React.lazy(
    () => import(/* webpackChunkName: "account-creation-page" */ './pages/account-creation')
);

const BusinessDetailsPage = React.lazy(
    () => import(/* webpackChunkName: "business-details-page" */ './pages/business-details')
);

const BillingAndPaymentPage = React.lazy(
    () => import(/* webpackChunkName: "billing-and-payment-page" */ './pages/billing-and-payment')
);

const PlanSelectionPage = React.lazy(
    () => import(/* webpackChunkName: "plan-selection-page" */ './pages/plan-selection')
);

const ReviewPage = React.lazy(() => import(/* webpackChunkName: "review-page" */ './pages/review'));

const SuccessPage = React.lazy(
    () => import(/* webpackChunkName: "success-page" */ './pages/success')
);

export const signupRoutes = [
    {
        path: SIGNUP_ROUTES.ACCOUNT_CREATION,
        component: <AccountCreationPage />,
    },
    {
        path: SIGNUP_ROUTES.BUSINESS_DETAILS,
        component: <BusinessDetailsPage />,
    },
    {
        path: SIGNUP_ROUTES.SELECT_PLAN,
        component: <PlanSelectionPage />,
    },
    {
        path: SIGNUP_ROUTES.PAYMENT,
        component: <BillingAndPaymentPage />,
    },
    {
        path: SIGNUP_ROUTES.REVIEW,
        component: <ReviewPage />,
    },
    {
        path: SIGNUP_ROUTES.SUCCESS,
        component: <SuccessPage />,
    },
];
