import { isInDevelopmentEnv } from 'src/shared/helpers/environment-utils';

export const NEARMECHECK_API_URL = 'https://near-me-check.uberall.com/api';
let UBERALL_API_URL = 'https://uberall.com';

if (isInDevelopmentEnv) {
    // NMC API doesn't have development environment so we should keep calling prod.

    UBERALL_API_URL = 'https://dev.uberall.com';
}

export interface FetchOptions extends RequestInit {
    body?: any;
}

async function fetchFromNearMeCheck(
    endpoint: string,
    publicKey: string,
    options: FetchOptions = {}
) {
    const response = await fetch(NEARMECHECK_API_URL.concat(endpoint), {
        ...options,
        headers: {
            'Content-Type': 'application/json',
            v: '20200219',
            publicKey,
            ...options.headers,
        },
        body: JSON.stringify(options.body),
    });

    const data: unknown = await response.json();

    return response.ok ? Promise.resolve(data) : Promise.reject(data);
}

export async function fetchFromUberall(endpoint: string, options: FetchOptions = {}) {
    const response = await fetch(UBERALL_API_URL.concat(endpoint), {
        ...options,
        headers: {
            'Content-Type': 'application/json',
            v: '20200219',
            ...options.headers,
        },
        body: JSON.stringify(options.body),
    });

    const data: any = await response.json();

    return response.ok ? Promise.resolve(data) : Promise.reject(data);
}

export default fetchFromNearMeCheck;
