/** @jsx jsx */
import { jsx, ThemeUIStyleObject } from '@theme-ui/core';

import { ExtraScope } from './contexts/extra-scope';

export const BASE_STYLES = {
    fontFamily: 'normal',
    lineHeight: 'default',
    color: 'nearMeCheck.grey.rich',
    backgroundColor: 'nearMeCheck.white',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    wordBreak: 'break-word',
    textAlign: 'left',
    '*': {
        boxSizing: 'border-box',
    },
    h1: {
        fontFamily: 'heading',
    },
    'input[type=text]': {
        height: 'auto',
        margin: 'auto',
    },
} as ThemeUIStyleObject;

export function BaseStyles({ children }: { children: React.ReactNode }) {
    return (
        <ExtraScope>
            <div sx={BASE_STYLES}>{children}</div>
        </ExtraScope>
    );
}
