import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import { ThemeProvider as ZenitThemeProvider } from '@uberall/zenit';

import { AppProps } from 'src/init/process-app-config';

import SentryErrorBoundary from './shared/sentry-error-boundary';
import { BaseStyles } from './shared/base-styles';
import { AppConfigProvider } from './shared/contexts/app-config';
import { AppThemeProvider } from './shared/contexts/theme';
import { AppRouter } from './app-router';
import { Devtools } from './devtools';
import { isInDevelopmentEnv } from './shared/helpers/environment-utils';

const queryConfig = {
    queries: {
        cacheTime: 3_600_000, // 1 hour,
        staleTime: 200,
        onError: isInDevelopmentEnv
            ? // eslint-disable-next-line no-console
              (error: unknown) => console.error('React Query rejected:', error)
            : undefined,
        retry: 1,
    },
};

export function App({ colorButtonBackground, colorSuccess, colorFailure, ...appConfig }: AppProps) {
    return (
        <ZenitThemeProvider>
            <AppThemeProvider
                // TODO GR-1250: Remove hardcoded default color (#7515F5) for colorButtonBackground
                colorButtonBackground={colorButtonBackground || '#7515F5'}
                colorSuccess={colorSuccess}
                colorFailure={colorFailure}
            >
                <style>{'body{margin:0}'}</style>
                <BaseStyles>
                    <SentryErrorBoundary>
                        <ReactQueryConfigProvider config={queryConfig}>
                            <Devtools>
                                <AppConfigProvider {...appConfig}>
                                    <AppRouter />
                                </AppConfigProvider>
                            </Devtools>
                        </ReactQueryConfigProvider>
                    </SentryErrorBoundary>
                </BaseStyles>
            </AppThemeProvider>
        </ZenitThemeProvider>
    );
}
