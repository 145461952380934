import countries from 'src/shared/helpers/countries.json';
import { directoryLogos } from 'src/assets/directory-logos';

import translationCodes from './translation-codes.json';

type ProcessedTranslationObject<T> = {
    [K in keyof T]: T[K] extends boolean ? string : ProcessedTranslationObject<T[K]>;
};

function processTranslationCodes<T>(
    prefix: string,
    translationObject: T
): ProcessedTranslationObject<T> {
    const processedEntries = Object.entries(translationObject).map(([key, value]) => {
        const newPrefix = getPrefix(prefix, key);

        return [
            key,
            value === true ? newPrefix : value && processTranslationCodes(newPrefix, value),
        ];
    });

    return Object.fromEntries(processedEntries);
}

function getPrefix(previousPrefix: string, key: string) {
    if (key === 'index') {
        return previousPrefix;
    }
    if (previousPrefix) {
        return `${previousPrefix}.${key}`;
    }
    return key;
}

function addProgrammaticCodes<T extends object>(codesObject: T) {
    return {
        ...codesObject,
        country: {
            name: Object.fromEntries(
                Object.keys(countries).map((key) => [key.toLowerCase(), true])
            ),
        },
        directory: {
            name: Object.fromEntries(
                Object.keys(directoryLogos).map((key) => [key.toLowerCase(), true])
            ),
        },
    };
}

export const translations = processTranslationCodes('', addProgrammaticCodes(translationCodes));
