/*

This file contains the theme which is used to systematize styling in our app.

**Any changes to this file need to be discussed with designer.**

*/

/* used for importing custom fonts */

import type { Theme } from '@theme-ui/core';
import { ThemeUIContextValue, useThemeUI } from '@theme-ui/core';

import './assets/fonts.css';

const TiemposFont = 'Tiempos Headline';
const IBMPlexFont = 'IBM Plex Sans';
const systemFontsStack = [
    'system-ui',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
];

function fontStack(fonts: string[]) {
    return fonts.map((font) => (font.includes(' ') ? `"${font}"` : font)).join(', ');
}

const basicColors = {
    // having the "nearMeCheck" object is important for scoping the color values
    nearMeCheck: {
        black: '#000000',
        blue: {
            light: '#3096DE', // blue.primary in zenit
            mid: '#254A89',
            dark: '#1C3144',
            brand: '#234598',
        },
        green: '#36D7A6',
        grey: {
            0: '#F6F9FD', // grey.4 in zenit
            1: '#DEE7EB',
            2: '#C1CCD2',
            3: '#76818E',
            5: '#EFF4F6', // grey.5 in zenit
            6: '#E0E7EB', // grey.6 in zenit
            7: '#BCC3CA', // grey.7 in zenit
            10: '#0E1A24', // grey.10 in zenit
            rich: '#151A20',
        },
        red: '#F43737',
        white: '#FFFFFF', // grey.0 in zenit
        brand: {
            aqua: '#76EAED',
            plum: '#260E5A',
            sky: '#08C0F9',
            ultraviolet: '#7515F5',
        },
    },
} as const;

const baseTypography = {
    fontFamily: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'grey.10',
    lineHeight: 'condensed',
} as const;

const lightTypography = {
    ...baseTypography,
    fontWeight: 'light',
} as const;

const boldTypography = {
    ...baseTypography,
    fontWeight: 'bold',
} as const;

const makeTheme = <T extends Theme>(t: T) => t;

// TODO: GR-1059 Implement improvements to Zenit
// Align the values between Zenit and NearMeCheck themes
export const nearMeCheckTheme = makeTheme({
    /**
     * Used for CSS properties:
     * border, border-top, border-right, border-bottom, border-left, border-block, border-block-end, border-block-start, border-inline, border-inline-end, border-inline-start
     */
    borders: ['0', '1px solid', '2px solid'],
    /**
     * Used for CSS properties:
     * border-width, border-top-width, border-bottom-width, border-left-width, border-right-width, border-block-end-width, border-block-start-width, border-block-width, border-inline-end-width, border-inline-start-width, border-inline-width
     */
    borderWidths: [0, 1, 2],
    /**
     * Used for breakpoints in array-like style properties. Needs to be an array of CSS length values.
     *
     * @example
     * <div sx={{ padding: [2, 3, 5] }}>
     * padding = 2 as a default, padding = 3 for first breakpoint and up and padding = 5 for second breakpoint and up
     *
     * @example
     * <div sx={{ padding: [null, null, 5] }}>
     * null can be used if no value should be set for a breakpoint, in this case padding is not set as default and only padding = 5 for second breakpoint and up
     *
     * @example
     * <div sx={{ padding: [2] }}> has same effect as <div sx={{ padding: 2 }}>
     */
    breakpoints: ['@media print, (min-width: 1200px)'],
    /**
     * Used for CSS properties:
     * color, background-color, border-color, caret-color, border-top-color, border-bottom-color, border-left-color, border-right-color, outline-color, fill, stroke
     */
    colors: {
        ...basicColors,
        whitelabel: {
            buttonBackground: basicColors.nearMeCheck.blue.light,
            buttonHoverBackground: basicColors.nearMeCheck.blue.mid,
            buttonActiveBackground: basicColors.nearMeCheck.blue.dark,
            success: basicColors.nearMeCheck.green,
            failure: basicColors.nearMeCheck.red,
        },
    },
    /**
     * Used for CSS properties:
     * font-family
     */
    fonts: {
        heading: fontStack([TiemposFont, ...systemFontsStack]),
        normal: fontStack([IBMPlexFont, ...systemFontsStack]),
    },
    /**
     * Used for CSS properties:
     * font-size
     */
    fontSizes: {
        0: 12,
        1: 14,
        2: 16,
        3: 18,
        4: 20,
        5: 24, // 22 in zenit
        6: 32, // 46 in zenit
        7: 40, // absent in zenit
        8: 48, // absent in zenit
    },
    /**
     * Used for CSS properties:
     * font-weight
     */
    fontWeights: {
        light: 300,
        normal: 400, // "regular" in zenit
        semibold: 500, // absent in zenit
        bold: 700, // 600 in zenit
    },
    /**
     * Used for CSS properties:
     * line-height
     */
    lineHeights: {
        condensedUltra: 1,
        condensed: 1.25,
        default: 1.5,
    },
    /**
     * Used for CSS properties:
     * border-radius, border-top-right-radius, border-top-left-radius, border-bottom-right-radius, border-bottom-left-radius, border-end-end-radius, border-end-start-radius, border-start-end-radius, border-start-start-radius
     */
    radii: [0, 3, 6],
    /**
     * Used for CSS properties:
     * box-shadow, text-shadow
     */
    shadows: {
        subtle: '1px 1px 2px rgba(0, 0, 0, 0.1)',
        small: '0px 2px 10px rgba(0, 0, 0, 0.15)',
        large: '1px 1px 20px rgba(0, 0, 0, 0.3)',
    },
    /**
     * Used for CSS properties:
     * width, min-width, max-width, height, min-height, max-height, flex-basis, block-size, inline-size, max-block-size, max-inline-size, min-block-size, min-inline-size
     */
    sizes: {
        xsmall: 544,
        small: 768,
        medium: 900,
        large: 1012,
        xlarge: 1280,
    },
    /**
     * Used for CSS properties:
     * margin, margin-top, margin-right, margin-bottom, margin-left, margin-block, margin-block-end, margin-block-start, margin-inline, margin-inline-end, margin-inline-start, padding, padding-top, padding-right, padding-bottom, padding-left, padding-block, padding-block-end, padding-block-start, padding-inline, padding-inline-end, padding-inline-start, inset, inset-block, inset-block-end, inset-block-start, inset-inline, inset-inline-end, inset-inline-start, top, right, bottom, left, grid-gap, grid-column-gap, grid-row-gap, gap, column-gap, row-gap
     */
    space: {
        '-4': -24,
        '-3': -16,
        '-2': -8,
        '-1': -4,
        0: 0,
        1: 4,
        2: 8,
        3: 16,
        4: 24,
        5: 32,
        6: 40, // 42 in zenit
        7: 48,
        8: 64,
        9: 80, // 96 in zenit
        10: 96, // 160 in zenit
        11: 112, // absent in zenit
        12: 128, // absent in zenit
    },
    text: {
        body: {
            ...baseTypography,
            fontSize: 1,
        },
        'body-600': {
            ...boldTypography,
            fontSize: 1,
        },
        bigNumbers: {
            ...boldTypography,
            fontSize: 3,
            lineHeight: 'default',
        },
        big: {
            ...boldTypography,
            fontSize: 4,
            lineHeight: 'default',
        },
        title: {
            ...boldTypography,
            fontFamily: 'heading',
            fontSize: 8,
            lineHeight: 'default',
        },
        subtitle: {
            ...boldTypography,
            fontSize: 4, // 4 is 20px, this should be 22px according to Zenit.
            lineHeight: '169%',
            letterSpacing: '0.3em',
            textTransform: 'uppercase',
        },
        description: {
            ...lightTypography,
            fontSize: 0,
            lineHeight: 'condensedUltra',
        },
        suggestion: {
            ...lightTypography,
            fontSize: 0,
            fontStyle: 'italic',
            color: 'grey.7',
        },
    },
});

export type NearMeCheckTheme = typeof nearMeCheckTheme;

interface ExactContextValue extends Omit<ThemeUIContextValue, 'theme'> {
    theme: NearMeCheckTheme;
}

export const useTheme = useThemeUI as unknown as () => ExactContextValue;
