import allCountries from 'src/shared/helpers/countries.json';
import validLanguages from 'src/shared/helpers/languages.json';

const DEFAULT_LANGUAGE = 'EN';
export const DEFAULT_CTA_URL = '';
export const DEFAULT_LOGIN_URL = 'https://uberall.com/en/app/uberall/login';

export type AppProps = {
    [K in keyof PropValidators]: ReturnType<PropValidators[K]>;
};
type PropValidators = typeof propValidators;
type ProcessedAppConfig = {
    rootNode: HTMLElement;
    props: AppProps;
};

export function processAppConfig(rootNode: HTMLElement): ProcessedAppConfig {
    const props = Object.fromEntries(
        Object.entries(propValidators).map(([prop, validator]) => [
            prop,
            validator(rootNode.dataset[prop]),
        ])
    ) as AppProps;

    // We delete all data-attributes from the rootNode so third-party code cannot read them and to make sure the validated <App> props are treated as the source of truth.
    Object.keys(rootNode.dataset).forEach((key) => delete rootNode.dataset[key]);

    return { props, rootNode };
}

type AttributeValue = string | undefined;
type AllCountries = typeof allCountries;
type ValidLanguage = keyof typeof validLanguages;

const propValidators = {
    colorButtonBackground: validateColor,
    colorSuccess: validateColor,
    colorFailure: validateColor,

    language: (value: AttributeValue): ValidLanguage => {
        const language = value?.toUpperCase();

        return language && language in validLanguages
            ? (language as ValidLanguage)
            : DEFAULT_LANGUAGE;
    },

    loginUrl: validateLoginUrl,
    privacyPolicyUrl: validateUrl,
    countries: validateCountryList,
    signupCountries: validateCountryList,
    ctaUrlSearchOptimization: validateCtaUrl,
    ctaUrlLocationAccuracy: validateCtaUrl,
    ctaUrlEngagement: validateCtaUrl,
    ctaUrlConversions: validateCtaUrl,
    ctaUrlResultsFooter: validateCtaUrl,
    emailFieldVisibility: showEmailOrPhone,
    phoneNumberFieldVisibility: showEmailOrPhone,
    signupEnabled: validateBoolean,
    printReportEnabled: validateBoolean,
    whiteLabelPrintReportLogo: validateUrl,
    showUncheckedDirectories: validateBoolean,
    hideUberallAttribution: validateBoolean,
    hideGoogleConnectFlow: validateBoolean,
    hideResultsFooter: validateBoolean,
    hideReplyRate: validateBoolean,
    hideLocationAccuracy: validateBoolean,
    hideSearchOptimization: validateBoolean,
    hideConversions: validateBoolean,
    hideEngagement: validateBoolean,
    hideSummary: validateBoolean,
    hideConclusion: validateBoolean,
    positionListingsDetails: (value: AttributeValue) => {
        const positionNumber = Number(value);
        if (positionNumber && !isNaN(positionNumber)) return positionNumber;
    },
    hideListingsDetails: validateBoolean,
    hideGlobalScore: validateBoolean,
    hideManagedLocationsDetails: validateBoolean,

    title: (value: AttributeValue) => {
        if (value && value.length < 100) {
            return value;
        }
    },

    subtitle: (value: AttributeValue) => {
        if (value && value.length < 500) {
            return value;
        }
    },

    reportName: (value: AttributeValue) => value || 'Hybrid CX Health Check',

    publicKey: (value: AttributeValue) => {
        if (!value) {
            throw Error('No data-public-key attribute provided on node #uberall-near-me-check.');
        }

        return value;
    },
} as const;

// We assign the value to an option element (or any other HTMLElement) and check whether the color was applied. If so, we know that the value is a valid CSS color.
export function validateColor(value: AttributeValue) {
    if (!value) {
        return;
    }

    const { style } = new Option();

    style.color = value;

    if (style.color) {
        return value;
    }
}

export function validateUrl(value: AttributeValue) {
    try {
        new URL(value!);
    } catch {
        return '';
    }

    return value as string;
}

export function validateCtaUrl(value: AttributeValue) {
    return validateUrl(value) || DEFAULT_CTA_URL;
}

export function validateBoolean(value: AttributeValue) {
    return value?.toLowerCase() === 'true';
}

export function showEmailOrPhone(value: AttributeValue) {
    if (value === 'optional' || value === 'mandatory') {
        return value;
    }
    return 'hidden';
}

export function validateLoginUrl(value: AttributeValue) {
    try {
        new URL(value!);
    } catch {
        return DEFAULT_LOGIN_URL;
    }

    return value as string;
}

function validateCountryList(value: AttributeValue): Partial<AllCountries> {
    const customCountries = value
        ?.split(',')
        .map((countryId) => countryId.trim().toUpperCase())
        .filter((countryId): countryId is keyof AllCountries =>
            Boolean(allCountries[countryId as keyof AllCountries])
        )
        .map((countryId) => [countryId, allCountries[countryId]]);

    return customCountries?.length ? Object.fromEntries(customCountries) : allCountries;
}
