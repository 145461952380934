export function importDevtools() {
    return import(/* webpackChunkName: "devtools" */ 'src/shared/devtools');
}

export function importResizeObserverPolyfill() {
    return import(/* webpackChunkName: "resize-observer-polyfill" */ 'resize-observer-polyfill');
}

export function importIntersectionObserverPolyfill() {
    return import(/* webpackChunkName: "intersection-observer-polyfill" */ 'intersection-observer');
}
