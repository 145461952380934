import { processAppConfig } from './process-app-config';
import { initI18next } from './init-i18next';
import { loadPolyfills } from './load-polyfills';

export async function init(rootNode: HTMLElement | null) {
    if (!rootNode) {
        throw Error('Could not find node with ID "uberall-near-me-check"');
    }

    const appConfig = processAppConfig(rootNode);

    initI18next(appConfig.props.language);

    await loadPolyfills();

    return appConfig;
}
